import React from 'react';
import styled from 'styled-components';
import { colors, components } from 'herald-fe-shared';

import TryCTA from './signup/CTATry';
import DemoCTA from './signup/CTADemo';
import { Container } from '~/layout/Layout';

interface ILargeCTAProps {
  title?: string;
  description?: string;
  demo?: boolean;
}

const Styled = styled.div`
  width: 100%;
  text-align: left;
  background-image: linear-gradient(
    -90deg,
    #6332a0 0%,
    ${colors.PURPLE_DARK()} 100%
  );
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3,
  p {
    color: ${colors.WHITE()};
  }
  h3 {
    margin-bottom: 0.5rem;
  }
  .left {
    margin-right: 30px;
    max-width: calc(100% - 300px);
  }
  button {
    padding: 1rem;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    flex-flow: column;
    align-items: flex-start;
    padding: 2rem;
    .left {
      margin-right: 0px;
      margin-bottom: 30px;
      max-width: none;
    }
  }
  @media screen and (max-width: 400px) {
    margin-top: 0;
    border-radius: 0;
    width: 100vw;
    margin-left: -2rem;
  }
`;

const LargeCTA: React.FC<ILargeCTAProps> = (props) => {
  const { title, description, demo } = props;
  return (
    <Container style={{ margin: '5rem auto' }} className="large-cta">
      <Styled>
        <div className="left">
          <h3>
            {title || (
              <>
                Start taking a data-driven approach to making something people
                want.
              </>
            )}
          </h3>
          <p>
            {description || (
              <>
                Herald helps you organize and quantify customer feedback to make
                better products.
              </>
            )}
          </p>
        </div>
        {demo ? (
          <components.Button large={true}>
            <DemoCTA demo={true} />
          </components.Button>
        ) : (
          <TryCTA primary={true} />
        )}
      </Styled>
    </Container>
  );
};

export default LargeCTA;
