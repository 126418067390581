import React from 'react';

import UseCaseSection from '~/components/use-cases/UseCaseSection';
import Silos from '~/illustrations/Silos';

const HeraldSolution: React.FC<{ firstSentence?: JSX.Element | string }> = (
  props
) => {
  const { firstSentence } = props;
  return (
    <div className="herald-solution">
      <UseCaseSection
        gray={true}
        orientation="left"
        title={<>Track every customer concern, effortlessly.</>}
        description={
          <p>
            {firstSentence ||
              `Herald provides a frictionless workflow to organize and quantify these "soft-closed" customer concerns.`}{' '}
            Your team is already doing most of the work to respond in a timely
            manner. With Herald, spending an extra 10 seconds per conversation
            gives you the superpower to retain and precisely recall any and all
            customer concerns.
          </p>
        }
        content={<Silos />}
        textStyle={{ maxWidth: '32rem' }}
      />
    </div>
  );
};

export default HeraldSolution;
