import * as React from "react";

const SvgSilos = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 339 292" fill="none" {...props}>
    <path
      d="M57.346 78.913L43.72 66.612a.595.595 0 00-.8.88L56.467 79.88a.653.653 0 10.878-.966z"
      fill="#040404"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.871 46.972c-6.667 1.176-8.321-24.156-8.321-24.156 17.727-10.396 18.648-5.064 21.756 12.92l.241 1.393 4.592 9.4-9.704 3.145-1.672-5.271c-2.043 1.138-4.399 2.13-6.892 2.569zM59.878 86.954l-6.97-3.075S44.096 72.394 41.84 68.506c-3.494-6.025 15.168 11.267 15.168 11.267s-1.025-5.944 0-6.757c.642-.51 3.895 7.577 3.895 7.577l3.074 3.286-1.23 4.304-2.87-1.23zm43.428 191.195l-1.23-12.709h7.584l-1.025 12.709h-5.329zm31.566-20.703l2.87 14.143h5.534l-1.025-14.143h-7.379z"
      fill="#BE6565"
    />
    <path
      d="M56.982 75.43s-.41-1.434 0-1.64c.41-.204 1.025 1.435 1.025 1.435l-1.025.206z"
      fill="#FFF9F9"
    />
    <path
      d="M109.25 272.409h-6.559l.82 6.354 5.329-.205.41-6.149zM143.071 266.67l-6.354.41 1.435 6.354 5.124-.41-.205-6.354z"
      fill="#E1D5D5"
    />
    <path
      d="M85.895 22.405c4.027 3.905 14.342.643 15.976 0l4.668-1.867s2.301 11.848 3.326 11.848 1.28-8.624 3.28-2.87c2 5.755.319 12.61 3.988 10.44 2.233-.315.784-17.498-2.843-24.647-2.831-3.949-17.652-.417-19.222 1.667-.987 1.31-10.637-.575-11.51.582-.872 1.158 2.337 4.847 2.337 4.847z"
      fill="#000"
    />
    <path
      d="M135.282 264.21l-21.112-58.622-3.895-11.068 18.653-40.175 15.168 109.865h-8.814z"
      fill="#253981"
    />
    <path
      d="M89.778 202.104l11.479-80.145 36.28-5.124 3.074 9.839c5.74 13.733-15.168 57.46-29.106 79.119v62.517h-10.863l-10.864-66.206z"
      fill="#22336F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.364 86.446c.8-6.798 2.81-21.466 5.762-26.593 2.477-4.302 5.893-9.764 9.089-12.855 3.079-3.861 7.105-3.587 9.628-.235.811.669 1.487 1.538 2.395 2.84 2.899 4.16 3.485 12.504 3.485 12.504l10.658 58.417c3.28 3.28-19.426 14.919-38.944 7.789l-1.777-27.684C82.54 96.935 61.287 88.754 61.287 88.754l1.025-8.404 36.052 6.096zm5.077 188.801s-15.054 9.892-18.366 11.287c-.885.373 0 2.46 0 2.46s25.558-.272 26.157-1.592c1.46-3.214 1.046-7.676.633-10.197-.162-.983-1.234-1.419-2.177-1.099-1.767.599-3.346.316-6.247-.859zm15.78 7.027c3.148-1.733 17.086-13.144 17.086-13.144 3.007.865 4.608.981 6.302.201.905-.417 2.017-.096 2.28.865.674 2.464 1.553 6.858.437 10.207-.459 1.375-25.848 4.318-25.848 4.318s-1.098-1.983-.257-2.447z"
      fill="#60979A"
    />
    <path
      d="M104.741 87.524s1.64-21.984 5.739-35.05c4.1-13.067 14.758-9.634 14.758 2.87 0 0-3.265 43.696-10.658 46.528-9.634 3.689-53.293-13.118-53.293-13.118l1.025-8.404 42.429 7.174z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      d="M136.922 278.764l2.869-4.1h1.435l-.82 1.23-2.254 2.87h-1.23zM138.972 278.764l2.869-4.1h1.435l-.82 1.23-2.255 2.87h-1.229zM141.021 278.764l2.87-4.1h1.435l-.82 1.23-2.255 2.87h-1.23z"
      fill="#32309B"
    />
    <path
      d="M145.736 278.969c-.656.491-18.175 3.552-26.647 4.919l.41 1.025c10.195-1.553 15.988-2.05 25.827-4.1l.41-1.844z"
      fill="#3D3737"
    />
    <path
      d="M125.033 280.813l-1.844-1.435 12.298-9.633-.41 4.509-10.044 6.559z"
      fill="#32309B"
    />
    <path
      d="M124.828 278.149l2.05 1.229-.205-2.459 2.665.82-.205-2.46 2.254.82v-2.46l2.05 1.435"
      stroke="#fff"
      strokeWidth={0.378}
    />
    <path
      d="M103.236 284.678l3.283-3.778 1.427.15-.944 1.138-2.542 2.618-1.224-.128zM105.275 284.892l3.282-3.777 1.427.15-.944 1.137-2.542 2.618-1.223-.128zM107.313 285.106l3.283-3.777 1.427.15-.944 1.137-2.542 2.619-1.224-.129z"
      fill="#32309B"
    />
    <path
      d="M111.71 285.938c-.704.42-18.283 1.576-26.851 2.049l.205 1.025c10.658-.205 18.242.205 26.236-1.435l.41-1.639z"
      fill="#3D3737"
    />
    <path
      d="M91.199 285.473l-1.685-1.619 13.238-8.296-.879 4.442-10.674 5.473z"
      fill="#32309B"
    />
    <path
      d="M91.273 282.802l1.91 1.437.054-2.467 2.564 1.094.053-2.468 2.157 1.051.257-2.446 1.889 1.641"
      stroke="#fff"
      strokeWidth={0.378}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.556 0A7.556 7.556 0 000 7.556v33.62a7.556 7.556 0 007.556 7.556h38.968a2.224 2.224 0 00.53 1.736l2.23 2.614a2.267 2.267 0 003.474-.03l2.155-2.614c.428-.519.576-1.13.505-1.706h12.046a7.556 7.556 0 007.556-7.556V7.556A7.556 7.556 0 0067.464 0H7.556z"
      fill="#fff"
      fillOpacity={0.46}
    />
    <rect
      x={4.509}
      y={6.97}
      width={67.641}
      height={27.466}
      rx={3.778}
      fill="#fff"
    />
    <rect
      x={4.509}
      y={37.306}
      width={22.137}
      height={8.199}
      rx={4.099}
      fill="#60BCFF"
    />
    <rect
      x={6.559}
      y={39.765}
      width={3.69}
      height={3.69}
      rx={1.845}
      fill="#fff"
      fillOpacity={0.51}
    />
    <rect
      x={11.478}
      y={40.175}
      width={11.888}
      height={2.87}
      rx={1.435}
      fill="#fff"
      fillOpacity={0.51}
    />
    <rect
      x={8.609}
      y={12.299}
      width={58.212}
      height={2.87}
      rx={1.435}
      fill="#000"
      fillOpacity={0.06}
    />
    <rect
      x={8.609}
      y={17.628}
      width={50.524}
      height={2.87}
      rx={1.435}
      fill="#000"
      fillOpacity={0.06}
    />
    <rect
      x={8.609}
      y={22.957}
      width={40.639}
      height={2.87}
      rx={1.435}
      fill="#000"
      fillOpacity={0.06}
    />
    <path
      d="M302.095 78.913l13.626-12.301a.595.595 0 01.8.88L302.973 79.88a.654.654 0 11-.878-.966z"
      fill="#040404"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256.569 46.972c5.591 0 10.305-20.94 2.722-24.834-7.273-3.735-11.341-4.265-16.117 14.417l-4.872 9.974 9.704 3.145 1.502-4.737c2.071 1.164 4.482 2.035 7.061 2.035zm42.994 39.982s3.821-2.24 6.427-3.674c4.41-2.427 5.811-5.66 7.151-8.75.474-1.094.941-2.17 1.53-3.186 3.494-6.025-12.239 8.43-12.239 8.43s1.025-5.945 0-6.758c-.642-.51-3.894 7.577-3.894 7.577l-3.075 3.286 1.23 4.304 2.87-1.23zm-43.429 191.195l1.23-12.709h-7.584l1.025 12.709h5.329zm-31.565-20.703l-2.87 14.143h-5.534l1.025-14.143h7.379z"
      fill="#FFB7B7"
    />
    <path
      d="M302.458 75.43s.41-1.434 0-1.64c-.41-.204-1.025 1.435-1.025 1.435l1.025.206z"
      fill="#FFF9F9"
    />
    <path
      d="M224.159 264.21l21.112-58.622 3.894-11.068-18.652-40.175-15.168 109.865h8.814z"
      fill="#16494B"
    />
    <path
      d="M269.662 202.104l-11.478-80.145-36.28-5.124-3.075 9.839c-8.937 19.062 15.168 57.46 29.106 79.119v62.517h10.864l10.863-66.206z"
      fill="#246467"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M261.076 86.446c-.799-6.798-2.809-21.466-5.761-26.593-2.477-4.302-5.893-9.764-9.089-12.855-3.079-3.861-7.106-3.587-9.629-.235-.81.669-1.486 1.538-2.395 2.84-2.898 4.16-3.484 12.504-3.484 12.504l-10.659 58.417c-3.279 3.28 19.426 14.919 38.945 7.789l1.777-27.684c16.121-3.694 37.373-11.875 37.373-11.875l-1.025-8.404-36.053 6.096zM257.238 275.4s15.225 9.628 18.56 10.965c.892.357.043 2.459.043 2.459s-25.558.174-26.181-1.135c-1.516-3.188-1.179-7.656-.81-10.184.144-.986 1.208-1.441 2.157-1.137 1.777.568 3.351.258 6.231-.968zm-17.019 6.873c-3.147-1.733-17.085-13.144-17.085-13.144-3.008.865-4.608.981-6.302.201-.905-.417-2.017-.096-2.28.865-.675 2.464-1.553 6.858-.437 10.207.458 1.375 25.847 4.318 25.847 4.318s1.099-1.983.257-2.447z"
      fill="#FFBA33"
    />
    <path
      d="M254.7 87.524s-1.64-21.984-5.74-35.05c-4.099-13.067-14.758-9.634-14.758 2.87 0 0 3.265 43.696 10.659 46.528 9.634 3.689 53.293-13.118 53.293-13.118l-1.025-8.404-42.429 7.174z"
      fill="#fff"
      fillOpacity={0.13}
    />
    <path
      d="M264.415 21.728s.183.902-1.23 1.844a5.855 5.855 0 01-.873.485c-3.52 1.563-5.995-2.002-8.761.745-2.12 2.106-1.025 7.67-1.025 10.659 0 10.043-4.803 5.766-6.149 8.814-1.862 4.214-7.256 3.357-11.068 5.944-2.4 1.628-1.025 13.733-1.025 13.733s-1.282 3-5.535 4.304c-5.035 1.545-14.607 6.834-13.733 1.64.555-3.293 4.958-8.159 5.33-11.478.569-5.088-6.355-3.08-6.355-8.2 0-5.118 7.8-4.937 9.839-9.633 1.276-2.937-.47-5.626 1.435-8.199 2.671-3.607 7.347-.715 10.044-4.304 2.172-2.892.075-3.094 1.639-6.354 2.412-5.026 6.56 0 10.454-4.92 3.894-4.919 19.472-.205 17.013 4.92z"
      fill="#100B31"
    />
    <path
      d="M321.192 64.362l9.224-1.23M330.826 63.132l7.174-1.23M319.143 62.722l6.559-11.888M320.782 62.927l5.74-4.51M327.546 57.598l5.535-4.715M326.727 61.23l6.203-3.791M333.696 57.13l3.074-1.787"
      stroke="#000"
      strokeOpacity={0.27}
      strokeWidth={0.756}
      strokeLinecap="round"
      strokeDasharray="3.78 3.78"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M293.02 26.647a6.058 6.058 0 00-6.058 6.058v7.151a6.058 6.058 0 006.058 6.058h9.584c-.042.41.056.843.329 1.229l.496.7a1.818 1.818 0 003.025-.09l.436-.7c.228-.364.308-.761.269-1.139h8.59a6.058 6.058 0 006.058-6.058v-7.151a6.058 6.058 0 00-6.058-6.058H293.02z"
      fill="#fff"
    />
    <circle cx={302.278} cy={35.404} r={2.608} fill="#FF6745" />
    <circle cx={306.491} cy={35.404} r={2.608} fill="#FF6745" />
    <path
      d="M304.485 40.42l3.711-3.01-3.811-1.203-3.712 1.203 3.812 3.01z"
      fill="#FF6745"
    />
  </svg>
);

export default SvgSilos;
