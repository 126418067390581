import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { icons, colors } from 'herald-fe-shared';

import UseCaseSection from '~/components/use-cases/UseCaseSection';
import { USE_CASES } from '~/components/use-cases/Opener';

const Styled = styled.div`
  .benefits {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
  }
  .benefits__section__illustration {
    width: 100%;
    max-width: 20rem;
  }
  .benefits__section__title {
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }
  .benefits__section__title__arrow {
    fill: ${colors.PURPLE_DARK()};
    width: 2rem !important;
    margin-left: 0.5rem;
    transition: 250ms all;
  }
  .benefits__section:hover .benefits__section__title__arrow {
    transform: translateX(10px);
  }
  .benefits__section__description {
    margin-top: 1rem;
  }
  @media screen and (max-width: 600px) {
    .benefits {
      grid-template-columns: 1fr;
    }
    .benefits__section__illustration {
      margin: auto;
    }
  }
`;

const BenefitsOfHerald: React.FC = () => {
  return (
    <Styled>
      <UseCaseSection
        gray={true}
        orientation="center"
        title={<>How Herald Helps</>}
        contentStyle={{ maxWidth: 'none', height: 'auto' }}
        content={
          <div className="benefits">
            {USE_CASES.map((u) => (
              <Link key={u.slug} to={`/use-cases/${u.slug}`}>
                <div className="benefits__section">
                  <div className="benefits__section__illustration">
                    {u.illustration}
                  </div>
                  <h4 className="benefits__section__title">
                    {u.title}
                    <icons.Arrow className="benefits__section__title__arrow" />
                  </h4>
                  <p className="benefits__section__description">
                    {u.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        }
      />
    </Styled>
  );
};

export default BenefitsOfHerald;
