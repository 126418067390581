import React from 'react';
import styled from 'styled-components';

import { SignupContext } from '~/layout/LayoutMarketing';

interface IProps {
  white?: boolean;
  style?: React.CSSProperties;
  demo?: boolean;
}

const CTADemo: React.FC<IProps> = (props) => {
  const { demo } = props;
  return (
    <SignupContext.Consumer>
      {(context: any) => (
        <div onClick={() => context.setOpen(true, demo)}>
          <h5>Request {demo ? 'Demo' : 'Invite'}</h5>
        </div>
      )}
    </SignupContext.Consumer>
  );
};

export default CTADemo;
