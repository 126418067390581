import React from 'react';
import styled from 'styled-components';
import { styles, colors } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';

const StyledUseCaseSection = styled.div`
  .section--gray {
    background: ${colors.GRAY_1()};
  }

  .section .container {
    justify-content: center;
    align-items: center;
  }

  .container--center {
    text-align: center;
    flex-flow: column;
  }

  .container__text {
    max-width: 40rem;
  }
  .container__text h3 {
    margin-bottom: 1rem;
  }

  .container--center .container__text {
    margin: auto;
    margin-bottom: 2rem;
  }
  .container--center .container__content {
    width: 100%;
    max-width: 36rem;
    margin: auto;
  }

  .container--left .container__content {
    margin-right: 4rem;
  }

  .container__content {
    flex: 1 1 auto;
    max-width: 30rem;
    height: 20rem;
  }
  .container__content svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 600px) {
    .container__content--screenshot {
      overflow: hidden;
      box-shadow: ${styles.BOX_SHADOW};
      border-radius: 5px;
    }
    .container--left,
    .container--right {
      text-align: center;
      flex-flow: column;
      .container__content {
        width: 100%;
        margin: 0;
        margin-bottom: 2rem;
      }
      .container__text {
        margin: auto;
      }
    }
    .container--left .container__content {
      order: 2;
    }
  }
`;

export const UseCaseScreenshot = styled.div`
  overflow: hidden;
  box-shadow: ${styles.BOX_SHADOW};
  border-radius: 5px;
`;

interface IUseCaseSectionProps {
  title: JSX.Element;
  description: JSX.Element;
  content: JSX.Element;
  orientation?: 'left' | 'center' | 'right';
  gray?: boolean;
  textStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  contentScreenshot?: boolean;
}

const UseCaseSection: React.FC<IUseCaseSectionProps> = (props) => {
  const {
    title,
    description,
    content,
    orientation,
    gray,
    textStyle,
    contentStyle,
    contentScreenshot,
  } = props;

  const position = orientation || 'center';

  return (
    <StyledUseCaseSection>
      <Section className={`section section--${gray ? 'gray' : 'white'}`}>
        <Container className={`container container--${position}`}>
          {position === 'left' && (
            <div
              className={`container__content container__content--${
                contentScreenshot ? 'screenshot' : 'image'
              }`}
              style={contentStyle}
            >
              {content}
            </div>
          )}
          <div className="container__text" style={textStyle}>
            <h3 className="text-gray">{title}</h3>
            {typeof description === 'string' ? (
              <p>{description}</p>
            ) : (
              description
            )}
          </div>
          {position !== 'left' && (
            <div
              className={`container__content container__content--${
                contentScreenshot ? 'screenshot' : 'image'
              }`}
              style={contentStyle}
            >
              {content}
            </div>
          )}
        </Container>
      </Section>
    </StyledUseCaseSection>
  );
};

export default UseCaseSection;
